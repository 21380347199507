var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: { "m-2": _vm.isPdv } },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "bar_tab_consumption-id",
                          label: _vm.$t("Id"),
                          type: "number",
                        },
                        model: {
                          value: _vm.filters.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "id", $$v)
                          },
                          expression: "filters.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isErp
                    ? _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("e-store-combo", {
                            attrs: { id: "filters-store", required: false },
                            model: {
                              value: _vm.filters.storeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "storeId", $$v)
                              },
                              expression: "filters.storeId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("e-bar-tab-number-input", {
                        attrs: {
                          id: "bar_tab_consumption-tab_number",
                          label: _vm.$t("Nº comanda"),
                        },
                        model: {
                          value: _vm.filters.tabNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "tabNumber", $$v)
                          },
                          expression: "filters.tabNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "bar_tab_consumption-consumerName",
                          label: _vm.$t("Nome do cliente"),
                        },
                        model: {
                          value: _vm.filters.consumerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "consumerName", $$v)
                          },
                          expression: "filters.consumerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: _vm.isErp ? 5 : 4 } },
                    [
                      _c("e-search-sku", {
                        attrs: {
                          id: "bar_tab_consumption-sku_id",
                          required: false,
                        },
                        model: {
                          value: _vm.filters.skuId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "skuId", $$v)
                          },
                          expression: "filters.skuId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "bar_tab_consumption-consumption_status",
                          label: _vm.$t("Status consumo"),
                          placeholder: _vm.$t("Selecione"),
                          type: "vue-select",
                          options: _vm.barTabConsumptionStatusOptions(),
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "bar_tab_consumption-rangeDate",
                          type: "date-range-picker",
                          label: _vm.$t("Período criação"),
                        },
                        model: {
                          value: _vm.filters.rangeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "rangeDate", $$v)
                          },
                          expression: "filters.rangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          staticClass: "mb-2",
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("BAR_TAB_CONSUMPTION.NAME"),
                "male"
              ),
              fields: _vm.fields,
              items: _vm.barTabConsumptions,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(status)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: row.item.status,
                        options: _vm.barTabConsumptionStatusOptions(),
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-delete": false,
                        "show-update": false,
                        "show-read-only": "",
                      },
                      on: {
                        "read-only": function ($event) {
                          return _vm.onShowBarTabConsumption(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _vm.isErp
                      ? _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { colspan: _vm.isErp ? 7 : 6 },
                            },
                            [
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(" " + _vm._s(_vm.$t("Total")) + " "),
                              ]),
                            ]
                          ),
                          _c("th", { staticClass: "text-right" }, [
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.getSummaryFields.totalNetValue
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "table-manage-pay-box",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bar-tab-consumption-info-sidebar", {
        ref: "barTabConsumptionInfoSidebar",
        attrs: { "is-history-view": "", "read-only": "", "is-erp": _vm.isErp },
        on: { "reload-data": _vm.getData, "show-sale": _vm.onShowSale },
      }),
      _c("sale-info-sidebar", {
        ref: "saleSidebar",
        attrs: { "is-pending-layout": false, "is-from-bar": "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }