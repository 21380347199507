<template>
  <section :class="{ 'm-2': isPdv }">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="bar_tab_consumption-id"
              v-model="filters.id"
              :label="$t('Id')"
              type="number"
            />
          </b-col>

          <b-col
            v-if="isErp"
            md="4"
          >
            <e-store-combo
              id="filters-store"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>

          <b-col md="2">
            <e-bar-tab-number-input
              id="bar_tab_consumption-tab_number"
              v-model="filters.tabNumber"
              :label="$t('Nº comanda')"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="bar_tab_consumption-consumerName"
              v-model="filters.consumerName"
              :label="$t('Nome do cliente')"
            />
          </b-col>

          <b-col :md="isErp ? 5 : 4">
            <e-search-sku
              id="bar_tab_consumption-sku_id"
              v-model="filters.skuId"
              :required="false"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="bar_tab_consumption-consumption_status"
              v-model="filters.status"
              :label="$t('Status consumo')"
              :placeholder="$t('Selecione')"
              type="vue-select"
              :options="barTabConsumptionStatusOptions()"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="bar_tab_consumption-rangeDate"
              v-model="filters.rangeDate"
              type="date-range-picker"
              :label="$t('Período criação')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      class="mb-2"
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('BAR_TAB_CONSUMPTION.NAME'), 'male')"
        :fields="fields"
        :items="barTabConsumptions"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(status)="row">
          <e-status-badge
            :status="row.item.status"
            :options="barTabConsumptionStatusOptions()"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-delete="false"
            :show-update="false"
            show-read-only
            @read-only="onShowBarTabConsumption(row.item)"
          />
        </template>

        <template #custom-foot>
          <tr v-if="isErp">
            <th
              class="text-right"
              :colspan="isErp ? 7 : 6"
            >
              <p class="m-0">
                {{ $t('Total') }}
              </p>
            </th>
            <th class="text-right">
              <p class="m-0">
                {{ getSummaryFields.totalNetValue | currency }}
              </p>
            </th>
          </tr>
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="table-manage-pay-box"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <bar-tab-consumption-info-sidebar
      ref="barTabConsumptionInfoSidebar"
      is-history-view
      read-only
      :is-erp="isErp"
      @reload-data="getData"
      @show-sale="onShowSale"
    />

    <sale-info-sidebar
      ref="saleSidebar"
      :is-pending-layout="false"
      is-from-bar
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import { barDomain, translate } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import EBarTabNumberInput from '@/views/components/inputs/EBarTabNumberInput.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import BarTabConsumptionInfoSidebar from './BarTabConsumptionInfoSidebar.vue'
import SaleInfoSidebar from '../pay-box-manage-sale/SaleInfoSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EStatusBadge,
    EFilters,
    EGridActions,
    ESearchSku,
    BarTabConsumptionInfoSidebar,
    EBarTabNumberInput,
    EStoreCombo,
    SaleInfoSidebar,
  },

  mixins: [translate, barDomain],

  props: {
    isErp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/pdv/payBoxBar/consumptions', {
      stPdvBarTabConsumptions: 'barTabConsumptions',
      stPdvPaging: 'paging',
      stPdvSorting: 'sorting',
      stPdvFilters: 'filters',
    }),
    ...mapState('pages/bar/consumptions', {
      stErpBarTabConsumptions: 'barTabConsumptions',
      stErpPaging: 'paging',
      stErpSorting: 'sorting',
      stErpFilters: 'filters',
    }),

    ...mapGetters('pages/pdv/payBoxBar/consumptions', {
      stPdvGetSummaryFields: 'getSummaryFields',
    }),
    ...mapGetters('pages/bar/consumptions', {
      stErpGetSummaryFields: 'getSummaryFields',
    }),

    barTabConsumptions() {
      return this.isErp ? this.stErpBarTabConsumptions : this.stPdvBarTabConsumptions
    },
    paging() {
      return this.isErp ? this.stErpPaging : this.stPdvPaging
    },
    sorting() {
      return this.isErp ? this.stErpSorting : this.stPdvSorting
    },
    filters() {
      return this.isErp ? this.stErpFilters : this.stPdvFilters
    },
    getSummaryFields() {
      return this.isErp ? this.stErpGetSummaryFields : this.stPdvGetSummaryFields
    },

    isPdv() {
      return !this.isErp
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          sortKey: 'barTab.store.id',
          tdClass: 'text-left',
          thStyle: { width: '280px' },
          formatter: (val, index, item) => this.$options.filters.storeName(item.barTab?.store),
          sortable: true,
          hide: this.isPdv,
        },
        {
          label: this.$t('Número'),
          key: 'barTab.number',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Status consumo'),
          key: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Data/Hora criado'),
          key: 'createdDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: (val, index, item) => this.$options.filters.datetime(item.createdDate, '-'),
        },
        {
          label: this.$t('Cliente'),
          key: 'consumerName',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, index, item) => item.consumerName || '-',
        },
        {
          label: this.$t('Total'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '130px' },
          formatter: (val, index, item) => this.$options.filters.currency(item?.netValue || 0),
        },
      ].filter(f => !f.hide)
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/pdv/payBoxBar/consumptions', {
      stPdvfetchBarTabConsumptions: 'fetchBarTabConsumptions',
      stPdvSetCurrentPage: 'setCurrentPage',
      stPdvSetPageSize: 'setPageSize',
      stPdvResetFilters: 'resetFilters',
    }),
    ...mapActions('pages/bar/consumptions', {
      stErpfetchBarTabConsumptions: 'fetchBarTabConsumptions',
      stErpSetCurrentPage: 'setCurrentPage',
      stErpSetPageSize: 'setPageSize',
      stErpResetFilters: 'resetFilters',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true

          if (this.isErp) {
            await this.stErpfetchBarTabConsumptions()
          } else {
            await this.stPdvfetchBarTabConsumptions()
          }
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      if (this.isErp) this.stErpResetFilters()
      else this.stPdvResetFilters()
      this.getData()
    },
    filterData() {
      if (this.isErp) this.stErpSetCurrentPage(1)
      else this.stPdvSetCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      if (this.isErp) this.stErpSetPageSize(pageSize)
      else this.stPdvSetPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      if (this.isErp) this.stErpSetCurrentPage(currentPage)
      else this.stPdvSetCurrentPage(currentPage)
      this.getData()
    },

    onShowBarTabConsumption(item) {
      this.$refs.barTabConsumptionInfoSidebar.show(item.id)
    },
    onShowSale(saleId) {
      this.$refs.saleSidebar.show(saleId)
    },
  },
}
</script>

<style lang="scss" scoped></style>
